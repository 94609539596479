@import "@fortawesome/fontawesome-free/css/all.css";
@import "flatpickr/dist/flatpickr.css";
@import "select2/dist/css/select2.css";
@import "dropzone/dist/dropzone.css";
@import "iv-viewer/dist/iv-viewer.css";
@import 'leaflet/dist/leaflet.css';

.turbolinks-progress-bar {
  height: 3px;
  margin-top: 56px;
}
